/* General styles */

:root {
  --color-positive: rgb(34, 197, 94);
  --color-negative: #d62d20;
  --color-neutral: #DAA520;
  --color-transparent: transparent;
  --color-hovered: #f0f0f0;
  --color-mature: #007bff;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #222;
  color: #fff;
}

/* Layout components */
.App { text-align: center; padding: 20px; }
.logout-container { display: flex; justify-content: flex-end; padding: 10px; }
.stockdata-container {
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}

/* Form styles */
.form-search, .form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.form-login { flex-direction: column; }

.form-search input[type="text"],
.form-login input[type="text"],
.form-login input[type="password"] {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}
.form-search input[type="text"] { width: 200px; margin-right: 10px; }
.form-login input[type="text"],
.form-login input[type="password"] { width: 250px; margin: 5px; }

.form-search input[type="text"]:focus,
.form-login input[type="text"]:focus,
.form-login input[type="password"]:focus {
  border-color: #007bff;
  background-color: #333;
}

.form-search button[type="submit"],
.form-login button[type="submit"] {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.form-login button[type="submit"] { width: 250px; margin: 5px; }

.form-search button[type="submit"]:hover,
.form-login button[type="submit"]:hover { background-color: #0056b3; }

/* Stock tile styles */
.stock-tile-container {
  width: 250px;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
}

.stock-tile-symbol-row,
.stock-tile-price-row {
  display: flex;
  align-items: center;
  margin: 10px;
}

.stock-tile-symbol-column-left,
.stock-tile-symbol-column-right,
.stock-tile-price-column-left,
.stock-tile-price-column-right {
  flex: 1;
  text-align: left;
}
.stock-tile-symbol-column-right { text-align: right; }

.stock-tile-symbol-label { font-size: 26px; font-weight: bold; }
.stock-tile-date-label { font-size: 14px; }

.stock-tile-price-entry-label,
.stock-tile-price-current-label {
  font-size: 14px;
  opacity: 0.75;
}

.stock-tile-price-price-label,
.stock-tile-price-current-price-label {
  font-size: 18px;
  font-weight: bold;
}

.stock-tile-stats-row {
  display: flex;
  margin: 0 10px;
  gap: 10px;
}

.stock-tile-stats-correlation-column,
.stock-tile-stats-sec-column,
.stock-tile-stats-earnings-column,
.stock-tile-stats-eps-column {
  padding: 5px;
  flex: 1;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.stock-tile-stats-correlation-label,
.stock-tile-stats-sec-label,
.stock-tile-stats-earnings-label,
.stock-tile-stats-eps-label {
  font-size: 12px;
  opacity: 0.75;
}

.stock-tile-stats-correlation-value,
.stock-tile-stats-sec-value,
.stock-tile-stats-earnings-value,
.stock-tile-stats-eps-value {
  font-size: 14px;
  font-weight: bold;
}

.stock-tile-sentiment-score {
  margin: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.stock-tile-sentiment-keywords {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.stock-tile-sentiment-challenge {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
}

.stock-tile-emojis { font-size: 24px; }
.stock-tile-chart { margin: 5px; }

/* Other components */
.main-logo img { width: 300px; }
.main-call { font-size: 18px; }
.table-container { max-height: 400px; overflow: auto; }

.logout-button {
  margin-bottom: 5px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.logout-button:hover { background-color: #c62828; }

.active-opportunities-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  width: 80%;
  max-width: 300px;
  border: 1px solid #555;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  z-index: 9999;
  padding: 20px;
}

.checkbox-symbol,
.modal-close-button {
  position: fixed;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  transition: color 0.3s ease;
}
.checkbox-symbol { top: 10px; left: 10px; }
.modal-close-button { top: 10px; right: 10px; }
.modal-close-button:hover { color: #ff4500; }

/* Spinner styles */
.spinner {
  margin-top: 20px;
  font-size: 18px;
}

.market-chart-container{
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .form-search {
    flex-direction: column;
  }
  .form-search input[type="text"],
  .form-search button[type="submit"] {
    width: 100%;
    margin: 5px 0;
  }
}